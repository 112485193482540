import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import CircularProgress from '@mui/material/CircularProgress';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { fetchHolidayEvents, fetchEvents } from '../../utils/cms';
import { useTranslationsContext } from '../../store/context/translations-context';
import EventDetails from './EventDetails'; // Event details component
import P4lIcon from '../UI/P4lIcon';
const Calendar = ({ initialView = 'dayGridMonth', views }) => {
  const [events, setEvents] = useState([]);
  const [locale, setLocale] = useState('pt'); // Default to Portuguese
  const [loading, setLoading] = useState(true); // Loading state
  const [isTranslating, setIsTranslating] = useState(true); // Translating state
  const [translations, setTranslations] = useState({
    holidays: '',
    ruleLabels: {},
  });
  const [eventLegend, setEventLegend] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null); // Store the clicked event
  const [drawerOpen, setDrawerOpen] = useState(false); // Control drawer visibility

  const { requestTranslation } = useTranslationsContext();

  const loadRuleTranslations = async () => {
    try {
      const iucTranslation = await requestTranslation('iuc-payment');
      const creditTranslation = await requestTranslation('credit-payment');
      const holidays = await requestTranslation('holidays'); // Use your actual translation key
      setTranslations(prev => ({
        ...prev,
        holidays:holidays,
        ruleLabels: {
          4: iucTranslation,
          3: creditTranslation,
        },
      }));
    } catch (error) {
      console.error('Error fetching rule translations:', error);
    } finally {
      setIsTranslating(false); // Set isTranslating to false after fetching translations
    }
  };

  const getEvents = async (startDate, endDate) => {
    try {
      const holidayEvents = await fetchHolidayEvents();
      const customEvents = await fetchEvents(startDate, endDate);
      const combinedEvents = [...holidayEvents, ...customEvents];

      const eventsWithRules = customEvents.filter(event => event.rule !== undefined);

      const uniqueLegend = eventsWithRules.reduce((acc, event) => {
        const eventRule = event.rule;
        const ruleLabel = translations.ruleLabels[eventRule] || `Rule ${eventRule}`;
        if (!acc.some(item => item.rule === ruleLabel)) {
          acc.push({ rule: ruleLabel, color: event.color });
        }
        return acc;
      }, []);

      setEventLegend(uniqueLegend);
      setEvents(
        combinedEvents.map(event => ({
          ...event,
          title: event.title,
        }))
      );
    } catch (error) {
      console.error('Error fetching events:', error);
    } finally {
      setLoading(false); // Set loading to false once events are fetched
    }
  };

  useEffect(() => {
    const loadLanguage = async () => {
      const language = localStorage.getItem('language') || 'pt';
      if (language === 'pt') {
        const ptLocale = (await import('@fullcalendar/core/locales/pt')).default;
        setLocale(ptLocale);
      } else {
        setLocale(language);
      }
    };

    loadLanguage();
    loadRuleTranslations(); // Load translations when the component mounts

    const getHolidays = async () => {
      try {
        const holidayEvents = await fetchHolidayEvents();
        setEvents(holidayEvents);
      } catch (error) {
        console.error('Error fetching holiday events:', error);
      }
    };

    getHolidays();
  }, []);

  useEffect(() => {
    const today = new Date();
    const startDate = new Date(today.getFullYear(), today.getMonth() -2, 1).toISOString();
    const endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0).toISOString();
    getEvents(startDate, endDate); // Fetch events for the current month when component mounts
  }, []);

  const handleDatesSet = (dateInfo) => {
    const startDate = new Date(dateInfo.start).toISOString();
    const endDate = new Date(dateInfo.end).toISOString();
    getEvents(startDate, endDate); // Fetch events for the new date range
  };

  const handleEventClick = (clickInfo) => {
    setSelectedEvent(clickInfo.event); // Store the clicked event
    setDrawerOpen(true); // Open the drawer
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false); // Close the drawer
  };

  const headerToolbar = {
    left: 'prev,next today',
    center: 'title',
    right: views ? views.join(',') : 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
  };

  const mobileHeaderToolbar = {
    right: 'prev,next today',
    center: 'title',
    left: views ? views.join(',') : 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
  };

  return (
    <div className="sm:p-4 overflow-x-auto">
      {loading || isTranslating ? (
        <div className="flex justify-center items-center h-96">
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="flex flex-wrap mb-4">
            <div className="flex items-center mr-4 mb-2">
              <div className="w-5 h-5 bg-[#10B981] mr-2"></div>
              <span className="text-sm">{translations.holidays}</span>
            </div>
            {eventLegend && eventLegend.map((legendItem, index) => (
              <div key={index} className="flex items-center mr-4 mb-2">
                <div
                  className="w-5 h-5 mr-2"
                  style={{ backgroundColor: legendItem.color }}
                ></div>
                <span className="text-sm">{legendItem.rule}</span>
              </div>
            ))}
          </div>

          <div className="">
            <FullCalendar
              plugins={[dayGridPlugin, listPlugin]}
              headerToolbar={window.innerWidth < 768 ? mobileHeaderToolbar : headerToolbar}
              initialView={initialView}
              events={events}
              eventDisplay="block"
              locale={locale}
              dayHeaderFormat={{ weekday: 'long' }}
              dayHeaderClassNames={() => 'capitalize'}
              datesSet={handleDatesSet}
              eventClick={handleEventClick} // Handle event click
              contentHeight="auto"
              eventDidMount={(info) => {
                info.el.style.cursor = 'pointer'; // Set cursor to pointer
              }}
              titleFormat={{ // Custom format for the title to include month name capitalization
                year: 'numeric',
                month: 'long', // Full month name (e.g., "January")
                day:'numeric'
              }}
            />
          </div>

          {/* MUI Drawer with the same layout as DrawerRight */}
          <Drawer
            anchor="right"
            open={drawerOpen}
            onClose={handleDrawerClose}
            sx={{
              '& .MuiDrawer-paper': {
                width: ['100%', '50%'], // Responsive width
                backgroundColor: theme => theme.palette.primary[300],
              },
              zIndex: theme => theme.zIndex.modal,
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 3 }}>
              <Typography variant="h6">{selectedEvent ? selectedEvent.title : 'Event Details'}</Typography>
              <P4lIcon
    onClick={handleDrawerClose}
    style={{
      width: '40px', // adjust as needed
      height: '40px',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}
     icon='p4l-cross' size='small'
  />


            </Box>
            <Box sx={{ p: 5, backgroundColor: theme => theme.palette.primary[300] }}>
              {selectedEvent ? (
                <EventDetails event={selectedEvent} />
              ) : (
                <Typography>No event selected</Typography>
              )}
            </Box>
          </Drawer>
        </>
      )}
    </div>
  );
};

export default Calendar;
