import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import P4lIcon from './P4lIcon';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import FormLayout from './DynamicForm/FormLayout';
import { fetchForm } from '../../utils/cms';
import { CircularProgress } from '@mui/material';
import SelectDatatable from './SelectDatatable';
import { useActionDataContext } from '../../store/context/action-context';
import { useLocation } from 'react-router-dom';

function DrawerRight(props) {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const width = '50%';

  const [formKey, setFormKey] = useState(0);
  const [loaderData, setLoaderData] = useState();

  const { actionData } = useActionDataContext();
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    const fetchFormData = async () => {
      setLoaderData(undefined);
      try {
        const data = await fetchForm(props.alias, 'form-panel');
        setLoaderData(data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchFormData();
    setFormKey((prevValue) => prevValue + 1);
  }, [props.alias, props.open]);

  useEffect(() => {
    if (props.open) {
      setDrawerOpen(true);
    } else {
      console.log('there is no form associated');
    }
  }, [props.open]);

  useEffect(() => {
    if (!actionData || !drawerOpen) return;
    if (actionData) {
      if (actionData.data.id) {
        const pathname = location.pathname;
        const segments = pathname.split('/');
        segments.pop();
        const newPath = props.destination
          ? `${props.destination}/${actionData.data.id}`
          : `${actionData.data.id}`;
        navigate(newPath);
      }
      if (actionData.ok) {
        setDrawerOpen(false);
      }
    }
    props.handleClose();
  }, [actionData]);

  function handleClose() {
    setDrawerOpen(false);
    props.handleClose();
  }

  return (
    <>
      <Drawer
        anchor='right'
        ModalProps={{ keepMounted: true }}
        onClose={props.selectBoxData ? props.onClose : handleClose}
        open={props.selectBoxData ? props.open : drawerOpen}
        sx={{
          '& .MuiDrawer-paper': {
            width: ['100%', width],
            backgroundColor: theme.palette.primary['300'],
          },
          zIndex: theme.zIndex.modal,
        }}
      >
        <Box className='sidebar-header p-6'>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              p: (theme) => theme.spacing(3, 3.255, 3, 5.255),
            }}
            className='p-6'
          >
            <Typography variant='h6'>{props.title}</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <P4lIcon   style={{
      width: '40px', // adjust as needed
      height: '40px',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }} icon='p4l-cross' size='small' onClick={props.selectBoxData ? props.onClose : handleClose} />
            </Box>
          </Box>
        </Box>

        <Box className='sidebar-body bg-primary-300' sx={{ p: (theme) => theme.spacing(5, 6) }}>
          {props.selectBoxData && (
            <SelectDatatable
              onClose={props.onClose}
              onConfirm={props.onConfirm}
              data={props.selectBoxData}
              onItemSelect={props.handleItemSelect}
            />
          )}
          {!props.selectBoxData && loaderData ? (
            <FormLayout formKey={formKey} context='new' formData={loaderData} />
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%', // Full height of the drawer
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Drawer>
    </>
  );
}

export default DrawerRight;
