import { Typography } from "@mui/material";
import FormLayout from "../components/UI/DynamicForm/FormLayout";
import LinkMui from '@mui/material/Link';
import { Link } from "react-router-dom";
import AuthForm from "../components/Auth/AuthForm";
import { json, useActionData, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useTranslationsContext } from "../store/context/translations-context";

function ForgotPassword() {
    const actionData = useActionData();
    const { requestTranslation } = useTranslationsContext(); // Access TranslationContext
    const navigate = useNavigate();
    const [translations, setTranslations] = useState({
        emailInstructions: '',
        backLogin: '',
        forgotPassword: ''
    });

    if (actionData && actionData.ok) {
        navigate("/notification", {
            state: {
                messages: {
                    title: "forgot-password",
                    success: 'notification-forgot-password-success',
                    instructions: "notification-forgot-password-instructions",
                },
                buttons: [{
                    text: "back-login",
                    link: "/login"
                }],
                email: actionData.email // Assuming you're sending back the email as part of the actionData
            }
        });
    }

    useEffect(() => {
        const loadTranslations = async () => {
            const emailInstructions = await requestTranslation('forgot-password-instructions');
            const backLogin = await requestTranslation('forgot-password-back-login');
            const forgotPassword = await requestTranslation('forgot-password');
            setTranslations({ emailInstructions, backLogin, forgotPassword });
        };

        loadTranslations();
    }, [requestTranslation]);

    return (
        <>
            <AuthForm social='disable'>
                <Typography className="text-white" variant='h5'>{translations.forgotPassword}</Typography>
                <Typography className="text-white" variant='body1'>
                    {translations.emailInstructions}
                </Typography>
                <FormLayout colSpan={12} formType="router" className="flex flex-col items-center gap-8" />
                <Typography className="flex gap-2 text-white" variant="body2">
                    <Link to="/login">
                        <LinkMui sx={{ color: 'primary.accent', textDecoration: 'underline' }}>
                            {translations.backLogin}
                        </LinkMui>
                    </Link>
                </Typography>
            </AuthForm>
        </>
    );
}

export default ForgotPassword;

export async function action({ request }) {
    const { REACT_APP_API_URL } = process.env;

    const data = await request.formData();

    const authData = {
        email: data.get('email'),
    };

    const response = await fetch(`${REACT_APP_API_URL}_allauth/browser/v1/auth/password/request`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': localStorage.getItem("csrfToken"),
        },
        credentials: 'include',
        body: JSON.stringify(authData),
    });

    if (response.status === 400) {
        const errorData = await response.json();
        return { ok: false, status: response.status, data: errorData };
    }

    if (!response.ok) {
        throw json({ message: 'Could not send password reset' }, { status: 500 });
    }

    await response.json();
    return { ok: true, response: response, email: authData.email };
}
