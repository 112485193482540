import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslationsContext } from '../../store/context/translations-context';

export default function Modal(props) {
  const { requestTranslation } = useTranslationsContext();

  const [transTitle, setTransTitle] = useState('');
  const [transMessage, setTransMessage] = useState('');
  const [transCancel, setTransCancel] = useState('');


  useEffect(() => {
    // Async function to fetch translations and update state
    const fetchTranslations = async () => {
      const title = await requestTranslation(props.title || 'Default Title');
      const message = await requestTranslation(props.message || '');
      const cancel = await requestTranslation('cancel');
      setTransTitle(title);
      setTransMessage(message);
      setTransCancel(cancel);

    };

    fetchTranslations();
  }, [props.title, props.message, requestTranslation]);



  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {transTitle}
      </DialogTitle>
      <DialogContent>
        {props.message ? (
          <DialogContentText id="alert-dialog-description">
            {transMessage}
          </DialogContentText>
        ) : (
          props.children
        )}
      </DialogContent>
      <DialogActions>
        {transCancel && (
          <Button onClick={props.onClose} autoFocus>
            {transCancel}
          </Button>
        )}
        {props.action && (
          <Button color="error" onClick={props.action}>
            {props.actionLabel || 'Confirm'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
