import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import { useTranslationsContext } from "../store/context/translations-context";
import { fetchFinancialDetails } from "../utils/cms";
import FinancialStatusNotInit from "../components/FinanceStatus/FinancialStatusNotInit";
import FinancialStatusPending from "../components/FinanceStatus/FinancialStatusPending";
import FinancialStatusValid from "../components/FinanceStatus/FinancialStatusValid";
import FinancialStatusInvalid from "../components/FinanceStatus/FinancialStatusInvalid";
import FinancialStatusEdit from "../components/FinanceStatus/FinancialStatusEdit"; // Import the FinancialStatusEdit component
import Button from "../components/UI/Button";

const Financials = () => {
  const [financialData, setFinancialData] = useState(null); // To store the fetched financial data
  const [isTranslating, setIsTranslating] = useState(true);
  const [showEditCredentials, setShowEditCredentials] = useState(false); // State to toggle edit credentials form
  const [isLoading, setIsLoading] = useState(false); // State for loading spinner
  const { requestTranslation } = useTranslationsContext(); // Access TranslationContext

  const [translatedLabels, setTranslatedLabels] = useState({
    home: '',
    profile: '',
    financials: '',
    financialPassword: '',
    nif: '',
    save: '',
    instructions: '',
    disclaimer: '',
    editCredentials: '', // New translation for the Edit Credentials button
    back: '', // New translation for the Back to Status button
  });

  useEffect(() => {
    const translateLabels = async () => {
      const home = await requestTranslation('home');
      const profile = await requestTranslation('profile');
      const financials = await requestTranslation('financials');
      const financialPassword = await requestTranslation('financial-password');
      const nif = await requestTranslation('nif');
      const save = await requestTranslation('save');
      const instructions = await requestTranslation('financial-instructions');
      const disclaimer = await requestTranslation('financial-disclaimer');
      const editCredentials = await requestTranslation('edit-credentials'); // Translate for "Edit Credentials" button
      const back = await requestTranslation('back'); // Translate for "Back to Status" button

      setTranslatedLabels({
        home,
        profile,
        financials,
        financialPassword,
        nif,
        save,
        instructions,
        disclaimer,
        editCredentials,
        back,
      });

      setIsTranslating(false); // Set translation loading to false after translations are fetched
    };

    translateLabels();
  }, [requestTranslation]);

  useEffect(() => {
    const fetchFinancialDetailsData = async () => {
      try {
        const data = await fetchFinancialDetails(); // Fetch financial details from API
        setFinancialData(data); // Assuming `data` has both `credentials_status` and `official_status`
      } catch (error) {
        console.error("Error fetching financial details:", error);
      }
    };

    fetchFinancialDetailsData();
  }, []);

  if (isTranslating || !financialData) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    );
  }

  const { credentials_status, official_status } = financialData;

  const renderContent = () => {
    // Toggle between showing financial status or the edit credentials form
    if (showEditCredentials) {
      return (
       <div className="flex flex-col">
        <Button
        className="w-fit"
        variant="outlined"
        color="primary"
        onClick={() => setShowEditCredentials(false)} // Trigger going back to FinancialStatusValid
        buttonText={translatedLabels.back} // Use translated "Back" text
      />

        <FinancialStatusEdit
          translatedLabels={translatedLabels}
          instructions={translatedLabels.instructions}
          disclaimer={translatedLabels.disclaimer}

        />

</div>
      )
    }

    // Render based on credentials_status and official_status
    if (credentials_status.status === 'PENDING') {
      return <FinancialStatusPending />;
    } else if (credentials_status.status === 'INVALID') {
      return (
        <FinancialStatusInvalid
          translatedLabels={translatedLabels}
          instructions={translatedLabels.instructions}
          disclaimer={translatedLabels.disclaimer}
        />
      );
    } else if (credentials_status.status === 'VALID') {
      return (
        <>
              <Button
            variant="contained"
            color="primary"

            onClick={() => setShowEditCredentials(true)} // Show edit credentials form
            buttonText={translatedLabels.editCredentials} // Use translated button text
            isLoading={isLoading} // Pass loading state
          />
          <FinancialStatusValid officialStatus={official_status} />

        </>
      );
    }
   else {
      return (
        <FinancialStatusNotInit
          translatedLabels={translatedLabels}
          instructions={translatedLabels.instructions}
          disclaimer={translatedLabels.disclaimer}
        />
      );
    }
  };

  return (
    <>
      <Breadcrumb
        data={[
          { label: translatedLabels.home, url: "/" },
          { label: translatedLabels.profile, url: "/profile" },
          { label: translatedLabels.financials, url: "/profile/financials" },
        ]}
      />

      <Box className="w-full p-6 bg-white">
        <Box className="w-full" sx={{ mb: 3 }}>
          <h3 className="mb-6">{translatedLabels.financials}</h3>

          {/* Render Content based on credentials_status and official_status */}
          {renderContent()}
        </Box>
      </Box>
    </>
  );
};

export default Financials;
