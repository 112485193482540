import React, { useState, useEffect } from "react";
import { FormControl } from "@mui/material";
import Input from "./Input";
import { useTranslationsContext } from "../../store/context/translations-context";

const LanguageSelector = (props) => {
  const [fieldValues, setFieldValues] = useState('');
  const language = localStorage.getItem('language');

  // Use the TranslationContext to fetch cached translations
  const { requestTranslation } = useTranslationsContext();

  const [translatedText, setTranslatedText] = useState({
    transSelectLabel: ''
  });

  useEffect(() => {
    const translateLabels = async () => {
      const transSelectLabel = await requestTranslation('select-language');
      setTranslatedText({ transSelectLabel });
    };

    translateLabels();
  }, [requestTranslation]);

  useEffect(() => {
    if (language) {
      setFieldValues(language);
    } else {
      localStorage.setItem('language', 'pt');
      setFieldValues('pt');
    }
  }, [language]);

  function handleLanguageChange(e) {
    localStorage.setItem('language', e.target.value);
    window.location.reload();
    setFieldValues(e.target.value);
  }

  return (
    <FormControl className="w-auto">
      <Input
        type={'select'}

        label={translatedText.transSelectLabel}
        onChange={handleLanguageChange}
        name={'select-lang'}
        value={fieldValues}
        color={ props.color =='dark' ? "primary.main": "primary.light"}
        className="text-white"
        size={props.size || 'medium'}
        icon={`p4l-${fieldValues}`}
        selectObject={{
          app_name: "core",
          model_name: "Language",
          field_key: "alias"
        }}
      />
    </FormControl>
  );
};

export default LanguageSelector;
