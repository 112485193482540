import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField"; // MUI's TextField instead of a custom Input component
import Form from "../components/UI/Form";
import { useNavigate, useParams } from "react-router-dom"; // For navigation
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import { createLeadGeneric } from "../utils/cms"; // Assuming this is the API function to create the lead
import { useSnackBar } from "../store/context/snackbar-context";
const LeadGeneric = (props) => {
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { type } = useParams();
  const navigate = useNavigate(); // For navigation purposes
  const { notify } = useSnackBar();
  React.useEffect(() => {
    if (!type) {
      navigate(-1); // Navigate back if no type parameter is present
    }
  }, [type, navigate]);

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true); // Start loading

    try {
      // Call the createLeadGeneric API with type and inputValue
      const response = await createLeadGeneric(type, inputValue);

      // Check if the response indicates success
      if (response.ok) {
        // Handle success (navigate to a success page or show a success message)
        notify("Your request was submitted", "success");
        navigate(-1);
      } else {
        // Handle failure (show an error message or navigate to an error page)
        notify("Something wrong with your request", "error");
        navigate(-1);
      }
    } catch (error) {
        notify("Something wrong with your request", "error");
        navigate(-1);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  const breadcrumbData = {
    data: [
      { label: "home", url: "/" },
      { label: "ask-product", url: "/ask-product" },
    ],
  };

  return (
    <>
      <Breadcrumb data={breadcrumbData.data} />
      <Form buttonText={'i-want-know-more'} onSubmit={handleSubmit} isLoading={isLoading}>
        <h3 className="mb-6">{"Saber Mais"}</h3>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <TextField
                label={"Informação adicional"}
                name="textInput"
                value={inputValue}
                onChange={handleChange}
                variant="outlined"
                fullWidth
                multiline
                rows={4}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Form>
    </>
  );
};

export default LeadGeneric;
