import React, { useState, useEffect, useMemo } from 'react';
import { useLoaderData, Link } from 'react-router-dom';
import MetaData from '../components/UI/MetaData';
import Chat from '../components/FloatingChat/Chat';
import ContentPage from './ContentPage';
import Swiper from '../components/Swiper/Swiper';
import { Box, Chip, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslationsContext } from '../store/context/translations-context';
import { fetchProfileCompleteness, fetchSwiperImages } from '../utils/cms';
import AlertsContainer from '../components/UI/AlertMessage/AlertsContainer';
import ProfileProgressBar from '../components/ProfileProgressBar/ProfileProgressBar';
import Calendar from '../components/Calendar/Calendar';
import StackedBarChart from '../components/Widgets/StackBarChart';
function Home() {
  const loaderData = useLoaderData();
  const meta = loaderData?.meta || "";
  const theme = useTheme();
  const [profileCompletenessItems, setProfileCompletenessItems] = useState([]);
  const { requestTranslation } = useTranslationsContext(); // Access TranslationContext
  const [isChipLoading, setIsChipLoading] = useState(true); // Loading state for Chip components
  const [swiperImages, setSwiperImages] = useState([]); // State for Swiper images

  const [translatedLabels, setTranslatedLabels] = useState({
    seguros: '',
    creditos: ''
  });

  const chartData = {
    "title": "Créditos",
    "labels": [
      {
        "reference": {
          "carro": { "color": "red", "value": 20000 },
          "casa": { "color": "blue", "value": 90000 },
          "outros": { "color": "orange", "value": 1500 }
        }
      },
      {
        "my-situation": {
          "carro": { "color": "red", "value": 10000 },
          "casa": { "color": "blue", "value": 60000 },
          "outros": { "color": "orange", "value": 10000 }
        }
      }
    ]
  };

  const chartData2 = {
    "title": "Prémio Anual",
    "labels": [
      {
        "reference": {
          "prémio": { "color": "blue", "value": 2000 },

        }
      },
      {
        "my-situation": {
          "prémio": { "color": "blue", "value": 3000 },
        }
      }
    ]
  };


  useEffect(() => {
    const translateLabels = async () => {
      const seguros = await requestTranslation('insurance');
      const creditos = await requestTranslation('credits');

      setTranslatedLabels({
        seguros,
        creditos
      });

      setIsChipLoading(false); // Mark chip translations as loaded
    };

    translateLabels();
  }, [requestTranslation]);

  useEffect(() => {
    async function loadData() {
      const profileCompletenessData = await fetchProfileCompleteness();
      setProfileCompletenessItems(profileCompletenessData || []);

      // Fetch images for Swiper
      const images = await fetchSwiperImages();
      setSwiperImages(images?.images || []); // Assuming response contains an 'images' array
    }

    loadData();
  }, []);

  const memoizedProfileCompletenessItems = useMemo(() => profileCompletenessItems, [profileCompletenessItems]);
  const issuesCount = memoizedProfileCompletenessItems?.steps?.length || 0;

  return (
    <React.Fragment>
      <MetaData data={JSON.stringify(meta)} />

      <div className='flex flex-col gap-16 '>
        {memoizedProfileCompletenessItems && <ProfileProgressBar progress={memoizedProfileCompletenessItems.percentage} />}

        {/* Alerts Container */}
        {memoizedProfileCompletenessItems.steps && <AlertsContainer issuesCount={issuesCount} steps={memoizedProfileCompletenessItems.steps || []} />}


        <div className="flex flex-col items-center gap-6">

<div className='flex flex-wrap justify-center gap-6 w-full'>
        <StackedBarChart chartData={chartData} />
        <StackedBarChart chartData={chartData2} />
        </div>
  <div className="w-full">
    <div className="flex justify-start sm:justify-center bg-gray-100 text-2xl overflow-x-auto whitespace-nowrap w-full">
      <button data-tab="seguros" data-click="showTabContent('seguros')" className="border tab-button inline-block min-w-64 py-9 px-2 transition-all duration-200 ease-out text-gray-400 hover:bg-white hover:text-black active:text-black font-bold">
        Seguros
      </button>
      <button data-tab="creditos" data-click="showTabContent('creditos')" className="border tab-button inline-block min-w-64 py-9 px-2 transition-all duration-200 ease-out text-gray-400 hover:bg-white hover:text-black active:text-black font-bold">
        Créditos
      </button>
    </div>
  </div>


  <div className="w-full p-6 bg-white">

    <div id="seguros" className="tab-content w-full hidden ">
      <div className="flex flex-col gap-6 w-full" id="accordion-seguros" data-accordion="collapse">

        <div className="flex flex-col items-center gap-2 w-full">
          <div id="accordion-collapse-heading-1" style={{ WebkitBoxShadow: '0px 0px 12px 0px #bdcdd1' }} className="flex items-center justify-between w-full sm:w-2/3 xl:w-1/2 px-6 py-2 sm:py-4 gap-3 font-medium text-gray-500 border border-b-0 border-gray-200 rounded-full" data-accordion-target="#accordion-collapse-body-1" aria-expanded="false" aria-controls="accordion-collapse-body-1">
            <div className="flex justify-between items-center w-full px-4">
              <div className="flex gap-4 items-center justify-center">
                <span className="p4l-family text-4xl text-green-400"></span>
                <span>Pessoal e Familiar</span>
              </div>
              <p className="text-green-400">Tem 5 Activos</p>
            </div>
            <svg data-accordion-icon className="w-3 h-3 rotate-180 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
          </div>

          <div id="accordion-collapse-body-1" className="flex border p-6 border-gray-200 flex-wrap w-full rounded-lg sm:w-2/3 xl:w-1/2 hidden" aria-labelledby="accordion-collapse-heading-1">

            <div style={{ WebkitBoxShadow: '0px 0px 12px 0px #bdcdd1' }} className="flex rounded-lg md:flex-row md:max-w-xl">
              <div className="p-6 rounded-t-lg md:rounded-none md:rounded-s-lg flex items-center justify-center">
                <span className="p4l-car text-4xl"></span>
              </div>
              <div className="flex flex-col items-center gap-4 w-full p-4 leading-normal">
                <p className="text-center">Instituição financeira</p>
                <div className="flex w-full justify-around">
                  <div className="flex flex-col gap-2 items-center px-8">
                    <p>Prémio</p>
                    <p className="font-extrabold">5000€</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="flex flex-col items-center gap-2 w-full">
          <div id="accordion-collapse-heading-2" style={{ WebkitBoxShadow: '0px 0px 12px 0px #bdcdd1' }} className="flex items-center justify-between w-full sm:w-2/3 xl:w-1/2 px-6 py-2 sm:py-4 gap-3 font-medium text-gray-500 border border-b-0 border-gray-200 rounded-full" data-accordion-target="#accordion-collapse-body-2" aria-expanded="false" aria-controls="accordion-collapse-body-2">
            <div className="flex justify-between items-center w-full px-4">
              <div className="flex gap-4 items-center justify-center">
                <span className="p4l-health text-4xl text-pink-400"></span>
                <span>Saúde</span>
              </div>
              <p className="text-pink-400">Tem 5 Activos</p>
            </div>
            <svg data-accordion-icon className="w-3 h-3 rotate-180 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
          </div>

          <div id="accordion-collapse-body-2" className="flex border p-6 border-gray-200 flex-wrap w-full rounded-lg sm:w-2/3 xl:w-1/2 hidden" aria-labelledby="accordion-collapse-heading-2">

            <div style={{ WebkitBoxShadow: '0px 0px 12px 0px #bdcdd1' }} className="flex rounded-lg md:flex-row md:max-w-xl">
              <div className="p-6 rounded-t-lg md:rounded-none md:rounded-s-lg flex items-center justify-center">
                <span className="p4l-car text-4xl"></span>
              </div>
              <div className="flex flex-col items-center gap-4 w-full p-4 leading-normal">
                <p className="text-center">Instituição financeira</p>
                <div className="flex w-full justify-around">
                  <div className="flex flex-col gap-2 items-center px-8">
                    <p>Prémio</p>
                    <p className="font-extrabold">5000€</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div id="creditos" className="tab-content w-full hidden ">
      <div className="flex flex-col gap-6 w-full" id="accordion-creditos" data-accordion="collapse">

        <div className="flex flex-col items-center gap-2 w-full">
          <div id="accordion-collapse-heading-4" style={{ WebkitBoxShadow: '0px 0px 12px 0px #bdcdd1' }} className="flex items-center justify-between w-full sm:w-2/3 xl:w-1/2 px-6 py-2 sm:py-4 gap-3 font-medium text-gray-500 border border-b-0 border-gray-200 rounded-full" data-accordion-target="#accordion-collapse-body-4" aria-expanded="false" aria-controls="accordion-collapse-body-4">
            <div className="flex justify-between items-center w-full px-4">
              <div className="flex gap-4 items-center justify-center">
                <span className="p4l-golden-bars text-4xl text-blue-400"></span>
                <span>Crédito Pessoal</span>
              </div>
              <p className="text-blue-400">Tem 3 Activos</p>
            </div>
            <svg data-accordion-icon className="w-3 h-3 rotate-180 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
          </div>

          <div id="accordion-collapse-body-4" className="flex border p-6 border-gray-200 flex-wrap w-full rounded-lg  sm:w-2/3 xl:w-1/2 hidden" aria-labelledby="accordion-collapse-heading-4">

            <div style={{ WebkitBoxShadow: '0px 0px 12px 0px #bdcdd1' }} className="flex rounded-lg md:flex-row md:max-w-xl">
              <div className="p-6 rounded-t-lg md:rounded-none md:rounded-s-lg flex items-center justify-center">
                <span className="p4l-car text-4xl"></span>
              </div>
              <div className="flex flex-col items-center gap-4 w-full p-4 leading-normal">
                <p className="text-center">Instituição financeira</p>
                <div className="flex w-full justify-around">
                  <div className="flex flex-col gap-2 items-center px-8">
                    <p>Prémio</p>
                    <p className="font-extrabold">3000€</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="flex flex-col items-center gap-2 w-full">
          <div id="accordion-collapse-heading-5" style={{ WebkitBoxShadow: '0px 0px 12px 0px #bdcdd1' }} className="flex items-center justify-between w-full sm:w-2/3 xl:w-1/2 px-6 py-2 sm:py-4 gap-3 font-medium text-gray-500 border border-b-0 border-gray-200 rounded-full" data-accordion-target="#accordion-collapse-body-5" aria-expanded="false" aria-controls="accordion-collapse-body-5">
            <div className="flex justify-between items-center w-full px-4">
              <div className="flex gap-4 items-center justify-center">
                <span className="p4l-home text-4xl text-green-400"></span>
                <span>Crédito Habitação</span>
              </div>
              <p className="text-green-400">Tem 2 Activos</p>
            </div>
            <svg data-accordion-icon className="w-3 h-3 rotate-180 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
          </div>

          <div id="accordion-collapse-body-5" className="flex border p-6 border-gray-200 flex-wrap w-full rounded-lg sm:w-2/3 xl:w-1/2 hidden" aria-labelledby="accordion-collapse-heading-5">

            <div style={{ WebkitBoxShadow: '0px 0px 12px 0px #bdcdd1' }} className="flex rounded-lg md:flex-row md:max-w-xl">
              <div className="p-6 rounded-t-lg md:rounded-none md:rounded-s-lg flex items-center justify-center">
                <span className="p4l-car text-4xl"></span>
              </div>
              <div className="flex flex-col items-center gap-4 w-full p-4 leading-normal">
                <p className="text-center">Instituição financeira</p>
                <div className="flex w-full justify-around">
                  <div className="flex flex-col gap-2 items-center px-8">
                    <p>Prémio</p>
                    <p className="font-extrabold">5000€</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="flex flex-col items-center gap-2 w-full">
          <div id="accordion-collapse-heading-6" style={{ WebkitBoxShadow: '0px 0px 12px 0px #bdcdd1' }} className="flex items-center justify-between w-full sm:w-2/3 xl:w-1/2 px-6 py-2 sm:py-4 gap-3 font-medium text-gray-500 border border-b-0 border-gray-200 rounded-full" data-accordion-target="#accordion-collapse-body-6" aria-expanded="false" aria-controls="accordion-collapse-body-6">
            <div className="flex justify-between items-center w-full px-4">
              <div className="flex gap-4 items-center justify-center">
                <span className="p4l-golden-bars text-4xl text-orange-400"></span>
                <span>Crédito Consolidado</span>
              </div>
              <p className="text-orange-400">Tem 4 Activos</p>
            </div>
            <svg data-accordion-icon className="w-3 h-3 rotate-180 shrink-0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5 5 1 1 5"/>
            </svg>
          </div>

          <div id="accordion-collapse-body-6" className="flex border p-6 border-gray-200 flex-wrap w-full rounded-lg sm:w-2/3 xl:w-1/2 hidden" aria-labelledby="accordion-collapse-heading-6">

            <div style={{ WebkitBoxShadow: '0px 0px 12px 0px #bdcdd1' }} className="flex rounded-lg md:flex-row md:max-w-xl">
              <div className="p-6 rounded-t-lg md:rounded-none md:rounded-s-lg flex items-center justify-center">
                <span className="p4l-car text-4xl"></span>
              </div>
              <div className="flex flex-col items-center gap-4 w-full p-4 leading-normal">
                <p className="text-center">Instituição financeira</p>
                <div className="flex w-full justify-around">
                  <div className="flex flex-col gap-2 items-center px-8">
                    <p>Prémio</p>
                    <p className="font-extrabold">7000€</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

        <Calendar
          initialView="listWeek" // Define the initial view
          views={['listWeek']} // Define the available views
        />

        <Chat />

        {/* Pass the fetched images to the Swiper component */}
        {swiperImages.length>0 && <Swiper images={swiperImages} />}

        <ContentPage />
      </div>
    </React.Fragment>
  );
}

export default Home;
