import React, { createContext, useContext, useState, useCallback, useRef } from 'react';
import { fetchTranslationFromLabel } from '../../utils/cms';

const TranslationContext = createContext({
  translations: {},
  requestTranslation: (label) => label, // Placeholder function
});

export const TranslationProvider = ({ children }) => {
  const [translations, setTranslations] = useState({});
  const pendingRequests = useRef({}); // Track ongoing requests

  const requestTranslation = useCallback(async (label) => {
    // Check if translation already exists
    if (translations[label]) {
      return translations[label];
    }

    // Avoid making duplicate requests for the same label
    if (pendingRequests.current[label]) {
      return pendingRequests.current[label]; // Return the pending promise
    }

    // Create a new promise and store it in pendingRequests
    pendingRequests.current[label] = new Promise(async (resolve, reject) => {
      try {
        const translation = await fetchTranslationFromLabel(label, localStorage.getItem('language') || 'pt');
        setTranslations((prev) => ({ ...prev, [label]: translation }));
        pendingRequests.current[label] = null; // Clear the pending request once it's resolved
        resolve(translation);
      } catch (error) {
        console.error(`Error fetching translation for ${label}:`, error);
        pendingRequests.current[label] = null; // Clear the pending request
        setTranslations((prev) => ({ ...prev, [label]: `\`${label}\`` }));
        resolve(`\`${label}\``); // Return the label as a fallback
      }
    });

    return pendingRequests.current[label];
  }, [translations]);

  return (
    <TranslationContext.Provider value={{ translations, requestTranslation }}>
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslationsContext = () => useContext(TranslationContext);
