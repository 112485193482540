import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Box } from '@mui/material';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const StackedBarChart = ({ chartData }) => {
  // Extract title and labels
  const { title, labels } = chartData;

  // Extract categories (e.g., carro, casa, etc.) from the first label in the array
  const categories = Object.keys(labels[0].reference); // Assume all labels have the same categories

  const datasets = categories.map((category) => ({
    label: category,
    data: labels.map((label) => label[Object.keys(label)[0]][category]?.value || 0), // Get the value for each category
    backgroundColor: labels.map(
      (label) => label[Object.keys(label)[0]][category]?.color || 'rgba(75, 192, 192, 0.6)' // Default color
    ),
    borderColor: 'transparent', // Transparent border

    maxBarThickness: 50, // Max bar thickness for a consistent design
  }));

  // Calculate the highest sum of the values in any of the rows
  const maxValue = Math.max(
    ...labels.map((label) =>
      categories.reduce((sum, category) => sum + (label[Object.keys(label)[0]][category]?.value || 0), 0)
    )
  );

  // Increase the maximum value by 30%
  const yAxisMax = maxValue * 2;

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false, // Allow resizing based on container
    animation: { duration: 500 },
    plugins: {
      title: {
        display: true,
        text: title || 'No title defined', // Fallback title if not provided
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          color: 'transparent',
        },

        ticks: { color: 'main' },

      },
      y: {
        stacked: true,
        beginAtZero: true,
        max: yAxisMax, // Set max Y-axis value to 30% above the highest sum
        grid: {
          color: '#ddd',
        },
        ticks: {

            color: 'main',
          },
          border: {
            dash: [2,4],
        },

      },
    },
  };

  const chartDataFormatted = {
    labels: labels.map((label) => Object.keys(label)[0]), // Extracting the label names (e.g., 'reference', 'my-situation')
    datasets: datasets,
  };

  return (
    <Box
      className="p-6 flex justify-center bg-white rounded-lg h-[30rem] w-full md:w-1/2 lg:w-1/3"
      style={{
        WebkitBoxShadow: '0px 0px 12px 0px #bdcdd1', // Adding subtle shadow effect
        maxWidth: '100%',
      }}
    >
      <Bar data={chartDataFormatted} options={chartOptions} />
    </Box>
  );
};

export default StackedBarChart;
