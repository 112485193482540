import React,{useState,useEffect} from 'react';
import { Box } from '@mui/material';



import 'react-perfect-scrollbar/dist/css/styles.css';

const MainLayout = ({ children }) => {

  const [minHeight, setMinHeight] = useState('100vh'); // Default to full viewport height

  useEffect(() => {
    const calculateHeight = () => {
      // Select the header element by class name, ID, or any other selector
      const header = document.querySelector('header');
      const footer = document.querySelector('footer');

      //console.log(header && main);
      if (header ) {
        const headerHeight = header.offsetHeight; // Get the current height of the header
        const footerHeight = footer.offsetHeight; // Get the current height of the header
        const viewportHeight = window.innerHeight;
      // Get the current height of the header
        //console.log(headerHeight);
        //console.log(viewportHeight)
        setMinHeight(`${viewportHeight  - footerHeight - headerHeight}px`); // Set the min-height subtracting the header height
      }
    };

    calculateHeight();

    // Update height on window resize
    window.addEventListener('resize', calculateHeight);

    // Cleanup listener on component unmount
    return () => window.removeEventListener('resize', calculateHeight);
  }, [minHeight]); // Empty dependency array to run only once on mount

  //console.log(minHeight);
  return (
    <Box className=" bg-primary-light text-primary-800 min-h-screen min-w-full mx-auto p-2 sm:p-6 relative mt-24  lg:gap-0 overflow-x-auto "
       >
      <div className='flex flex-col  gap-12 w-full'>

        {children}
      </div>
    </Box>
  );
};

export default MainLayout;