import React, { useEffect, useState } from 'react';
import { useLoaderData,json } from 'react-router-dom';
import DOMPurify from 'dompurify'; // Import DOMPurify for sanitizing HTML
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import { Link } from 'react-router-dom';
import parse, { domToReact } from 'html-react-parser';
import { getCSRFToken } from '../utils/auth';
import { Drawer } from '@mui/material';
import Chartjs from '../components/Widgets/Chart';
import Box from '@mui/material/Box'; // Box for layout
import Typography from '@mui/material/Typography'; // Typography for text
import { fetchDynamicContent } from '../utils/cms';
import IconButton from '@mui/material/IconButton'; // IconButton for the close button
import CloseIcon from '@mui/icons-material/Close'; // Close icon from MUI
import {  useTheme } from '@mui/material/styles';
import Modal from '../components/UI/Modal';
import P4lIcon from '../components/UI/P4lIcon';
import {useSnackBar} from '../store/context/snackbar-context';
function ContentPage() {
  const loaderData = useLoaderData();
  const theme = useTheme();
  const htmlData = loaderData?.items[0];
  const width = "50%"
  console.log(loaderData);
  const [content, setContent] = useState(null);
  const [drawers, setDrawers] = useState({});
  const [drawerContents, setDrawerContents] = useState({}); // To store contents for multiple drawers
  const [drawerTitles, setDrawerTitles] = useState({}); // To store titles for multiple drawers
  const [drawerUrls, setDrawerUrls] = useState({}); // Store URLs for drawers that require API fetching

  const [modals, setModals] = useState({}); // To store modal states (open/closed)
  const [modalContents, setModalContents] = useState({}); // To store contents for multiple modals
  const [modalTitles, setModalTitles] = useState({}); // Store modal titles
  const [modalUrls, setModalUrls] = useState({}); // Store URLs for modals that require API fetching

const breadcrumb = loaderData?.breadcrumb?.data || '';
const { notify } = useSnackBar();


useEffect(() => {
  window.notify = (message, level = 'info') => {
    notify(message, level); // Call the notify function with the provided message and level
  };

  // Cleanup function to remove the global notify function when component unmounts
  return () => {
    delete window.notify;
  };
}, [notify]);

 // Tags={}
  // Extend the allowed tags for DOMPurify
  DOMPurify.addHook('uponSanitizeElement', (node, data) => {
    if (node.tagName && (node.tagName.toLowerCase() === 'chart' || node.tagName.toLowerCase() === 'bootstrap' || node.tagName.toLowerCase() === 'iframe' || node.tagName.toLowerCase() === 'drawer' || node.tagName.toLowerCase() === 'modal')) {
      data.allowedTags[node.tagName.toLowerCase()] = true;


    }
  });
  DOMPurify.addHook('uponSanitizeAttribute', (node, data) => {
    if (node.tagName.toLowerCase() === 'drawer' || node.tagName.toLowerCase() === 'modal') {
      data.allowedAttributes['title'] = true;
      data.allowedAttributes['id'] = true; // Allow drawer to have a unique ID
      data.allowedAttributes['url'] = true; // Allow drawer to have a unique ID
      data.allowedAttributes['message'] = true; // Allow drawer to have a unique ID
      data.allowedAttributes['action'] = true; // Allow drawer to have a unique ID
    }
    if (['chart', 'bootstrap', 'iframe'].includes(node.tagName.toLowerCase())) {
      data.allowedAttributes[data.attrName] = true;
    }
  });


  const loadDrawerContent = async (drawerId, url) => {
    try {
      const contentData = await fetchDynamicContent(url); // Assuming the API returns { html: '<html content>', title: 'Drawer Title' }
      const sanitizedHtml = DOMPurify.sanitize(contentData.items[0].text); // Sanitize fetched HTML content
      console.log(contentData)
      console.log(sanitizedHtml);
      // Update content and title from the API response
      setDrawerContents((prev) => ({ ...prev, [drawerId]: parse(sanitizedHtml) }));
      setDrawerTitles((prev) => ({ ...prev, [drawerId]: contentData.html.title || 'Default Title' }));
    } catch (error) {
      setDrawerContents((prev) => ({ ...prev, [drawerId]: 'Error loading content.' }));
    }
  };

  // Function to load modal content and title dynamically
  const loadModalContent = async (modalId, url) => {
    try {
      const contentData = await fetchDynamicContent(url); // Assuming the API returns { html: '<html content>', title: 'Modal Title' }
      const sanitizedHtml = DOMPurify.sanitize(contentData.html); // Sanitize fetched HTML content

      // Update content and title from the API response
      setModalContents((prev) => ({ ...prev, [modalId]: parse(sanitizedHtml) }));
      setModalTitles((prev) => ({ ...prev, [modalId]: contentData.title || 'Default Modal Title' }));
    } catch (error) {
      setModalContents((prev) => ({ ...prev, [modalId]: 'Error loading content.' }));
    }
  };

const sanitizeAndParseContent = () => {
  if (!htmlData || !htmlData.text) return null;
  const safeHTML = DOMPurify.sanitize(htmlData.text, { ADD_TAGS: ['chart', 'bootstrap', 'iframe', 'drawer','modal'],  ADD_ATTR: ['target', 'data-click', 'title', 'id','url'], });
  const options = {
    replace: (domNode) => {
      if (domNode.attribs && domNode.name === 'chart') {
        return <Chartjs type={domNode.attribs.type} data={domNode.attribs.data} options={domNode.attribs.options} />;
      } else if (domNode.attribs && domNode.name === 'a') {
        let href = domNode.attribs.href;
        let className = domNode.attribs.class;
        let target = domNode.attribs.target;
        return (
          <Link className={className} target={target} to={href}>
            {domToReact(domNode.children, options)}
          </Link>
        );
      }else if (domNode.attribs && domNode.name === 'drawer') {
        const drawerId = domNode.attribs.id || 'drawer-1';
        const drawerUrl = domNode.attribs.url || null;

        if (drawerUrl) {
          setDrawerUrls((prev) => ({ ...prev, [drawerId]: drawerUrl }));
          setDrawerContents((prev) => ({ ...prev, [drawerId]: 'Loading...' }));
        } else {
          const drawerHtml = domToReact(domNode.children, options);
          setDrawerContents((prev) => ({ ...prev, [drawerId]: drawerHtml }));
        }

        setDrawerTitles((prev) => ({ ...prev, [drawerId]: domNode.attribs.title || 'Default Title' }));
        if (domNode.attribs.open === 'true') {
          setDrawers((prev) => ({ ...prev, [drawerId]: true }));
        }

        return <></>;
      } else if (domNode.attribs && domNode.name === 'modal') {
        const modalId = domNode.attribs.id || 'modal-1';
        const modalUrl = domNode.attribs.url || null;

        if (modalUrl) {
          setModalUrls((prev) => ({ ...prev, [modalId]: modalUrl }));
          setModalContents((prev) => ({ ...prev, [modalId]: 'Loading...' }));
        } else {
          const modalHtml = domToReact(domNode.children, options);
          setModalContents((prev) => ({ ...prev, [modalId]: modalHtml }));
        }

        setModalTitles((prev) => ({ ...prev, [modalId]: domNode.attribs.title || 'Default Modal Title' }));
        setModals((prev) => ({ ...prev, [modalId]: false }));

        return <></>;

      }
    },
  };
  return parse(safeHTML, options);
};


useEffect(() => {
  // Load drawer content when URLs are available
  Object.keys(drawerUrls).forEach((drawerId) => {
    if (!drawerContents[drawerId] || drawerContents[drawerId] === 'Loading...') {
      loadDrawerContent(drawerId, drawerUrls[drawerId]);
    }
  });
  // Load modal content when URLs are available
  Object.keys(modalUrls).forEach((modalId) => {
    if (!modalContents[modalId] || modalContents[modalId] === 'Loading...') {
      loadModalContent(modalId, modalUrls[modalId]);
    }
  });
}, [drawerUrls, drawerContents, modalUrls, modalContents]);


useEffect(() => {

  window.showTabContent = function(tabId) {
    const tabContents = document.querySelectorAll('.tab-content');
    const tabButtons = document.querySelectorAll('.tab-button');

    // Hide all tab content
    tabContents.forEach(content => content.classList.add('hidden'));

    // Remove the active class from all tab buttons
    tabButtons.forEach(button => button.classList.remove('active'));

    // Show the selected tab content
    const selectedTab = document.getElementById(tabId);
    if (selectedTab) {
        selectedTab.classList.remove('hidden');
    }

    // Add the active class to the corresponding tab button
    const activeButton = document.querySelector(`[data-tab="${tabId}"]`);
    if (activeButton) {
        activeButton.classList.add('active');
    }
};

window.showFirstTab = function() {
    const firstTab = document.querySelector('.tab-content');
    const firstTabButton = document.querySelector('.tab-button');

    if (firstTab) {
        firstTab.classList.remove('hidden');
    }

    if (firstTabButton) {
        firstTabButton.classList.add('active');
    }
};



  const sanitizedContent = sanitizeAndParseContent();
  setContent(sanitizedContent);

  const loadScript = (src, callback) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = callback;
    document.head.appendChild(script);
  };



  const genericFunctionsScriptSrc = `${process.env.REACT_APP_API_URL}static/cms/js/lib.js`;
  loadScript(genericFunctionsScriptSrc, () => {
    console.log('Additional script loaded successfully');
    if (typeof window.doStuff === 'function') {
      window.doStuff();
      const buttons = document.querySelectorAll('[data-click]');
      buttons.forEach(button => {
        const clickHandler = new Function(button.getAttribute('data-click'));
        //const toggleHandler = window[button.getAttribute('id')];
        //console.log(button.getAttribute('id'))
        button.addEventListener('click', clickHandler);

       // button.addEventListener('click', window.toggleDrawer(button.getAttribute('id')));
      });
      // Ensure the first tab is shown after everything is set up
      window.showFirstTab();
      console.log("generic script initialized!")
    } else {
      console.log('doStuff function is not dedined');
    }
  });




  return () => {

    const script = document.querySelector('script[src="https://cdnjs.cloudflare.com/ajax/libs/flowbite/2.4.1/flowbite.js"]');
    if (script) {
      document.head.removeChild(script);
    }
    const genericFunctionsScript = document.querySelector(`script[src="${genericFunctionsScriptSrc}"]`);
    if (genericFunctionsScript) {
      document.head.removeChild(genericFunctionsScript);
    }

  };
  // eslint-disable-next-line
}, []);

useEffect(() => {
  if (content) {




    const flowbiteScript = document.createElement('script');
    flowbiteScript.type = 'application/javascript';
    flowbiteScript.text = `
      if (typeof initFlowbite !== 'undefined') {
        initFlowbite();
      } else {
        console.warn('initFlowbite function is not defined');
      }
    `;
    document.body.appendChild(flowbiteScript);

    // Run the doStuff function again if the content changes
    if (typeof window.doStuff === 'function') {
      window.doStuff();



      // Ensure the first tab is shown after everything is set up
      window.showFirstTab();
      console.log("doStuff function called after content change!");
    } else {
      console.log('doStuff function is not defined after content change');
    }



  }
}, [content]); // Dependency array includes 'content'

const toggleDrawer = (drawerId) => {
  setDrawers((prevDrawers) => {
    const newState = { ...prevDrawers, [drawerId]: true };
    console.log("Drawer state updated:", newState); // Log the updated state for debugging
    return newState;
  });
};

// Function to toggle modal state reliably
const toggleModal = (modalId) => {
  setModals((prevModals) => {
    const newState = { ...prevModals, [modalId]: true };
    console.log("Modal state updated:", newState); // Log the updated state for debugging
    return newState;
  });
};


useEffect(() => {
  window.toggleDrawer = toggleDrawer;
  window.toggleModal = toggleModal;

  console.log(toggleDrawer);
  console.log(toggleModal);
  const observer = new MutationObserver((mutationsList, observer) => {
    for (let mutation of mutationsList) {
      if (mutation.type === 'childList') {

          // Initialize your script and event listeners here
          initScriptAndHandlers();
          observer.disconnect(); // Stop observing after initialization
          break;

      }
    }
  });

  // Target element for observation
  const contentElement = document.getElementById('content-dynamic'); // Use your specific content container ID or ref
  if (contentElement) {
    observer.observe(contentElement, { childList: true, subtree: true });
  }

  return () => {

    delete window.toggleDrawer;
    delete window.toggleModal;
    observer.disconnect();} // Clean up when component unmounts
}, []);

const initScriptAndHandlers = () => {
  const buttons = document.querySelectorAll('[data-click]');

    buttons.forEach(button => {
      const clickHandler = new Function(button.getAttribute('data-click'));
      button.removeEventListener('click', clickHandler);
      button.addEventListener('click', clickHandler);
      console.log("Event listener added to button:", button);
    });
};

return (
  <React.Fragment>
  <Breadcrumb data={breadcrumb} />
  <div id="content-dynamic" className="w-full">{content || <p>Loading...</p>}</div>

  {/* Material-UI Drawer with Custom Layout */}
  {Object.keys(drawerContents).map((drawerId) => (
    <Drawer
      key={drawerId}
      anchor="right"
      open={!!drawers[drawerId]}
      onClose={() => setDrawers((prev) => ({ ...prev, [drawerId]: false }))}
      ModalProps={{ keepMounted: true }}
      sx={{
        '& .MuiDrawer-paper': {
          width: ['100%', width],
          backgroundColor: theme.palette.primary[300],
        },
        zIndex: theme.zIndex.modal,
      }}
    >
      <Box className="sidebar-header p-6" sx={{ padding: theme.spacing(3, 3.255, 3, 5.255) }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6">{drawerTitles[drawerId]}</Typography>
          <P4lIcon style={{
      width: '40px', // adjust as needed
      height: '40px',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }} icon='p4l-cross' size='small'  onClick={() => setDrawers((prev) => ({ ...prev, [drawerId]: false }))}/>


        </Box>
      </Box>

      {/* Drawer Content */}
      <Box className="sidebar-body bg-primary-300" sx={{ padding: theme.spacing(5, 6) }}>
        {
          drawerContents[drawerId]
        }
      </Box>
    </Drawer>
  ))}

  {/* Material-UI Modal with Custom Layout */}
  {Object.keys(modalContents).map((modalId) => (
    <Modal
      key={modalId}
      open={!!modals[modalId]}
      title={modalTitles[modalId]}
      onClose={() => setModals((prev) => ({ ...prev, [modalId]: false }))}
    >
      {
        modalContents[modalId]
      }
    </Modal>
  ))}
</React.Fragment>
);
}
export default ContentPage;




export async function loader({ params, request }) {
  const { REACT_APP_API_URL } = process.env;

  const token = getCSRFToken();
  console.log(token);

  const id = params.id || 'home';

  // Get the URL from the request object
  const url = new URL(request.url);
const language=localStorage.getItem('language') || 'pt'
  // Base API URL
  let apiUrl = `${REACT_APP_API_URL}webapp/item/html/${id}?language=${language}`;
  // Get the URL from the request object

  // Check if there are any query parameters
  const paramsList = Array.from(url.searchParams.entries());

  if (paramsList.length > 0) {
    // Create a query string from the parameters
    const queryString = paramsList
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&');

    apiUrl += `&${queryString}`; // Append the query string to the API URL
  }

  try {
    const response = await fetch(apiUrl, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': token,
      },
      credentials: "include"
    });

    if (!response.ok) {
      throw json({ message: response.statusText }, { status: response.status });
    }

    const data = await response.json();
    console.log(data);

    return data;
  } catch (error) {
    throw json({ message: error.message }, { status: error.status });
  }
}

