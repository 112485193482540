import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "../UI/Button"; // Custom button component
import { useMediaQuery, useTheme } from "@mui/material";

const Form = ({ children, buttonText, onSubmit,isLoading }) => {
  const [buttonBottom, setButtonBottom] = useState("1rem");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Adjust button position on scroll or resize events
  useEffect(() => {
    const adjustButtonPosition = () => {
      const footerElement = document.querySelector("footer");
      if (!footerElement) return;

      const footerRect = footerElement.getBoundingClientRect();
      const viewportHeight = window.innerHeight;

      if (footerRect.top < viewportHeight) {
        const overlap = viewportHeight - footerRect.top;
        setButtonBottom(overlap + "px");
      } else {
        setButtonBottom("1rem");
      }
    };

    window.addEventListener("scroll", adjustButtonPosition);
    window.addEventListener("resize", adjustButtonPosition);
    adjustButtonPosition(); // Call initially to set the button position

    return () => {
      window.removeEventListener("scroll", adjustButtonPosition);
      window.removeEventListener("resize", adjustButtonPosition);
    };
  }, []);

  return (
    <form className="w-full bg-white p-6" onSubmit={onSubmit}>
      {children}
      <Grid container xs={12} sm={1}>
        <div
          className={isMobile ? "w-full fixed bottom-0 left-0 p-6" : "w-full fixed bottom-0 left-0 p-6"}
          style={{
            bottom: buttonBottom,
            transition: "bottom",

          }}
        >
          <Box className="flex justify-center">
            <Button
              className={isMobile ? "w-full" : "w-64 min-w-64"}
              type="submit"
              buttonText={buttonText}
              fullWidth={isMobile}
              isLoading={isLoading}
              size="large"
              sx={{
                backgroundColor: theme.palette.primary.main,
                '&:hover': {
                  backgroundColor:'#252959',
                },
                borderRadius: "2.5rem",
                fontWeight: 700,
                color: "#fff", // Ensure the text is visible
              }}
            />
          </Box>
        </div>
      </Grid>
    </form>
  );
};

export default Form;
