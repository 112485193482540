import React, { useState } from "react";
import { Box, Grid, FormControl, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom"; // For navigation
import Input from "../UI/Input"; // Custom input component
import Form from "../UI/Form"; // Reusable form component
import { financeAtCheck, updateFinancePassword } from "../../utils/cms"; // API functions

const FinancialStatusEdit = ({ translatedLabels, instructions, disclaimer }) => {
  const [fieldValues, setFieldValues] = useState({
    nif: "",
    financialPassword: "",
  });
  const [isLoading, setIsLoading] = useState(false); // Loading state for the button
  const navigate = useNavigate(); // For navigating to the notification page

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { nif, financialPassword } = fieldValues;

    setIsLoading(true); // Start loading

    try {
      // First, update the financial password
      const updatePasswordResponse = await updateFinancePassword(nif, financialPassword);

      // Check if the password update was successful
      if (updatePasswordResponse.ok) {
        // Call financeAtCheck after successfully updating the password
        const atCheckResponse = await financeAtCheck(nif);

        // Handle success if financeAtCheck succeeds
        if (atCheckResponse.ok) {
          // Redirect to the notification page after success
          navigate("/notification", {
            state: {
              messages: {
                title: "finance-password-update",
                success: "notification-finance-password-update-success",
                instructions: "notification-finance-password-update-instructions",
              },
              buttons: [
                {
                  text: "back",
                  link: "/profile/financials",
                },
              ],
            },
          });
        } else {
          throw new Error(atCheckResponse.message || "Failed to check status.");
        }
      } else {
        throw new Error(updatePasswordResponse.message || "Failed to update the password.");
      }
    } catch (error) {
      // Navigate to notification page in case of failure
      navigate("/notification", {
        state: {
          messages: {
            title: "finance-password-update",
            error: "notification-finance-password-update-error",
            instructions: "notification-finance-password-update-error-instructions",
          },
          buttons: [
            {
              text: "try-again",
              link: "/profile/financials",
            },
          ],
        },
      });
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  return (
    <Form buttonText={translatedLabels.save} onSubmit={handleSubmit} isLoading={isLoading}>
      <p className="mb-6">{instructions}</p>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <Input
              type="number"
              label={translatedLabels.nif}
              name="nif"
              value={fieldValues.nif}
              onChange={handleChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <Input
              type="password"
              label={translatedLabels.financialPassword}
              name="financialPassword"
              value={fieldValues.financialPassword}
              onChange={handleChange}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Typography className="mt-6" style={{ color: "#d32f2f", fontWeight: "bold" }}>
        {disclaimer}
      </Typography>
    </Form>
  );
};

export default FinancialStatusEdit;
