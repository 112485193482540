import React, { useState, useEffect } from 'react';
import { useTranslationsContext } from '../../store/context/translations-context';
import { fetchFinancialDetails } from '../../utils/cms';

const FinanceStatus = () => {
    const { requestTranslation } = useTranslationsContext(); // Access the translation context

    const [translatedText, setTranslatedText] = useState({
        transFinancials: ''
    });
    const [financialStatus, setFinancialStatus] = useState({
        official_status: '',
        credentials_status: ''
    }); // Store financial status from API

    useEffect(() => {
        const translateLabels = async () => {
            const transFinancials = await requestTranslation('financials');
            setTranslatedText({ transFinancials });
        };

        translateLabels();
    }, [requestTranslation]);

    useEffect(() => {
        const fetchFinancialStatus = async () => {
            try {
                const data = await fetchFinancialDetails(); // Fetch financial details from API
                console.log(data);
                setFinancialStatus(data); // Assuming the data has `official_status` and `credentials_status`
            } catch (error) {
                console.error("Error fetching financial status:", error);
                // Optionally set fallback data here
            }
        };

        fetchFinancialStatus();
    }, []);

    // Determine the emoji based on financialStatus
    const financialEmoji = (() => {
        const { official_status, credentials_status } = financialStatus;

        if (credentials_status.status === 'NOT_INIT' || credentials_status.status === 'INVALID') {
            return '😐'; // Sad emoji if credentials_status is NOT_INIT
        }

        if (official_status.at_status === 'RED') {
            return '😢'; // Sad emoji for unchecked, not found, or red statuses
        }


        if (official_status.at_status === 'GREEN') {
            return '😊'; // Smiley emoji for green status
        }

        return '😐'; // Default neutral emoji
    })();

    return (
        <div className="flex items-center gap-2 font-medium text-gray-900 dark:text-white me-3">
            {translatedText.transFinancials} {financialEmoji}
        </div>
    );
};

export default FinanceStatus;
