import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Input from "../UI/Input"; // Custom input component
import Form from "../UI/Form"; // Reusable form component
import { financeInit, financeAtCheck } from "../../utils/cms"; // API functions
import { useNavigate } from "react-router-dom"; // For navigation

const FinancialStatusNotInit = ({ translatedLabels, instructions, disclaimer }) => {
  const [fieldValues, setFieldValues] = useState({
    nif: "",
    financialPassword: "",
  });
  const [isLoading, setIsLoading] = useState(false); // Loading state for the button
  const navigate = useNavigate(); // For navigating to the notification page

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { nif, financialPassword } = fieldValues;

    setIsLoading(true); // Start loading

    try {
      // Call financeInit API with nif and password as arguments
      const initResponse = await financeInit(nif, financialPassword);

      // Check if financeInit was successful
      if (initResponse.ok) {
        // Call financeAtCheck after financeInit
        const atCheckResponse = await financeAtCheck(nif);

        // Handle success if both APIs succeed
        if (atCheckResponse.ok) {
          // Navigate to the notification page after both APIs succeed
          navigate("/notification", {
            state: {
              messages: {
                title: "finance-init",
                success: 'notification-finance-init-success',
                instructions: "notification-finance-init-instructions",
              },
              buttons: [
                {
                  text: "back",
                  link: "/profile/financials",
                },
              ],
            },
          });
        } else {
          throw new Error(atCheckResponse.message || "AT Status Check failed.");
        }
      } else {
        throw new Error(initResponse.message || "Finance Init failed.");
      }
    } catch (error) {
      // Handle error
      console.error(error.message);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  return (
    <Form buttonText={translatedLabels.save} onSubmit={handleSubmit} isLoading={isLoading}>
      <p className="mb-6">{instructions}</p>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <Input
              type="number"
              label={translatedLabels.nif}
              name="nif"
              value={fieldValues.nif}
              onChange={handleChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <Input
              type="password"
              label={translatedLabels.financialPassword}
              name="financialPassword"
              value={fieldValues.financialPassword}
              onChange={handleChange}
            />
          </FormControl>
        </Grid>
      </Grid>
      <p className="mt-6" style={{ color: "#d32f2f", fontWeight: "bold" }}>{disclaimer}</p>
    </Form>
  );
};

export default FinancialStatusNotInit;
