import React from 'react';
import Calendar from '../components/Calendar/Calendar';

export default function Agenda() {
  return (
    <div className='overflow-x-auto '>

      <Calendar
        initialView="dayGridMonth" // Define the initial view
        views={['dayGridMonth']} // Define the available views
      />
    </div>
  );
}
