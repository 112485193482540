import { Outlet } from 'react-router-dom';
import Nav from '../components/Nav/Nav';
import Box from '@mui/material/Box';

import Footer from '../components/Footer/Footer';
import MainSection from '../components/UI/MainSection';
import FloatingMenu from '../components/Nav/FloatingMenu/FloatingMenu';
import React, { useState, useEffect, useMemo, useRef, useCallback, useContext } from 'react';
import AuthContext from '../store/context/auth-context__';

import { useNavigate, useNavigation } from 'react-router-dom';
import { fetchMenuItems } from '../utils/cms';
import { CircularProgress} from '@mui/material';

import ScrollToTop from '../components/ScrollToTop';

function Root() {
  const drawerWidth = 240;
  const [open, setOpen] = useState(false);
  const navigation = useNavigation();
  const navigate = useNavigate();
  const footerRef = useRef(null);

  const [sideBarItems, setSidebarItems] = useState([]);
  const [topNavItems, setTopNavItems] = useState([]);
  const [footerItems, setFooterItems] = useState([]);

  const [floatingMenuItems, setFloatingMenuItems] = useState({ items: [] });

  const authCtx = useContext(AuthContext);

  useEffect(() => {
    authCtx.refetch();
    if (!authCtx.isLoggedIn) {
      navigate("/login");
    }
  }, [authCtx.isLoggedIn, navigate]);

  useEffect(() => {
    if (!authCtx.isLoggedIn) return;

    async function loadData() {
      const sidebarData = await fetchMenuItems('sidebar-menu');
      const footerData = await fetchMenuItems('footer-menu');
      const topNavData = await fetchMenuItems('top-menu');
      const floatingMenuData = await fetchMenuItems('floating-menu');

      setSidebarItems(sidebarData || []);
      setFooterItems(footerData || []);
      setTopNavItems(topNavData || []);

      setFloatingMenuItems(floatingMenuData || { items: [] });
    }

    loadData();
  }, [authCtx.isLoggedIn]);

  const handleDrawerOpen = useCallback(() => setOpen(true), []);
  const handleDrawerClose = useCallback(() => setOpen(false), []);

  const memoizedSideBarItems = useMemo(() => sideBarItems, [sideBarItems]);
  const memoizedTopNavItems = useMemo(() => topNavItems, [topNavItems]);
  const memoizedFooterItems = useMemo(() => footerItems, [footerItems]);

  const memoizedFloatingMenuItems = useMemo(() => floatingMenuItems, [floatingMenuItems]);

  // Calculate the number of issues


  return (
    authCtx.isLoggedIn &&
    <React.Fragment>
      <ScrollToTop>
        <Box className="w-full" sx={{ height: '100%', display: 'flex' }}>
          <Nav
            topNavItems={memoizedTopNavItems}
            sideBarItems={memoizedSideBarItems}
            drawerWidth={drawerWidth}
            open={open}
            handleDrawerClose={handleDrawerClose}
            handleDrawerOpen={handleDrawerOpen}
          />
          <Box sx={{ flexGrow: 1, minWidth: 0, display: 'flex', flexDirection: 'column', minHeight: '100vh' }} className="w-full bg-primary-300">
            <main sx={{ flexGrow: 1, width: '100%', transition: 'padding .25s ease-in-out' }}>

              <MainSection>

                <FloatingMenu footerRef={footerRef} data={memoizedFloatingMenuItems} />

                {navigation.state === 'loading' ? (
                  <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 'screen' }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Outlet />
                )}
              </MainSection>
            </main>
            <Footer ref={footerRef} footerItems={memoizedFooterItems} />
          </Box>
        </Box>
      </ScrollToTop>
    </React.Fragment>
  );
}

export default Root;
