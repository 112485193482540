import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Input from "../components/UI/Input"; // Custom input component
import Button from "../components/UI/Button"; // Custom button component
import { changePassword } from "../utils/auth"; // API function
import { useMediaQuery, useTheme, CircularProgress } from "@mui/material";
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import { useTranslationsContext } from "../store/context/translations-context";
import { Typography } from "@mui/material";

const ChangePassword = () => {
  const [fieldValues, setFieldValues] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "", // Add confirmPassword state
  });
  const [passwordMismatchError, setPasswordMismatchError] = useState(''); // For displaying password mismatch error
  const [buttonBottom, setButtonBottom] = useState("1rem");
  const [isTranslating, setIsTranslating] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate(); // Use navigate for navigation

  const { requestTranslation } = useTranslationsContext();
  const [translatedLabels, setTranslatedLabels] = useState({
    home: '',
    account: '',
    changePassword: '',
    currentPassword: '',
    newPassword: '',
    confirmPassword: '', // Add confirmPassword label
    save: '',
    passwordsDoNotMatch: '', // Add passwordsDoNotMatch for translation
  });

  useEffect(() => {
    const translateLabels = async () => {
      const home = await requestTranslation('home');
      const account = await requestTranslation('account');
      const changePassword = await requestTranslation('change-password');
      const currentPassword = await requestTranslation('current-password');
      const newPassword = await requestTranslation('new-password');
      const confirmPassword = await requestTranslation('confirm-password'); // Fetch confirmPassword label
      const save = await requestTranslation('save');
      const passwordsDoNotMatch = await requestTranslation('passwords-do-not-match'); // Fetch password mismatch message

      setTranslatedLabels({
        home,
        account,
        changePassword,
        currentPassword,
        newPassword,
        confirmPassword,
        save,
        passwordsDoNotMatch, // Set the translated mismatch message
      });

      setIsTranslating(false);
    };

    translateLabels();
  }, [requestTranslation]);

  const breadcrumbData = {
    data: [
      { label: translatedLabels.home, url: "/" },
      { label: translatedLabels.account, url: "/account" },
      { label: translatedLabels.changePassword, url: "/account/change-password" },
    ],
  };

  useEffect(() => {
    const adjustButtonPosition = () => {
      const footerElement = document.querySelector("footer");
      if (!footerElement) return;

      const footerRect = footerElement.getBoundingClientRect();
      const viewportHeight = window.innerHeight;

      if (footerRect.top < viewportHeight) {
        const overlap = viewportHeight - footerRect.top;
        setButtonBottom(overlap + "px");
      } else {
        setButtonBottom("1rem");
      }
    };

    window.addEventListener("scroll", adjustButtonPosition);
    window.addEventListener("resize", adjustButtonPosition);
    adjustButtonPosition();

    return () => {
      window.removeEventListener("scroll", adjustButtonPosition);
      window.removeEventListener("resize", adjustButtonPosition);
    };
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const { currentPassword, newPassword, confirmPassword } = fieldValues;

    // Password confirmation validation
    if (newPassword !== confirmPassword) {
      setPasswordMismatchError(translatedLabels.passwordsDoNotMatch); // Use translated error message
      return;
    }

    try {
      setIsLoading(true);
      await changePassword(currentPassword, newPassword);
      setIsLoading(false);

      // Redirect to notification page with success message
      navigate('/notification', {
        state: {
          messages: {
            title: 'password-changed',
            success: 'password-changed-success',
            instructions: 'password-change-success-instructions',
          },
          buttons: [{
            text: 'continue',
            link: '/',
          }],
        },
      });
    } catch (error) {
      setIsLoading(false);

      // Redirect to notification page with failure message
      navigate('/notification', {
        state: {
          messages: {
            title: 'password-change-failed',
            success: 'password-change-failed-message',
            instructions: 'password-change-failed-instructions',
          },
          buttons: [{
            text: 'retry',
            link: '/account/change-password',
          }],
        },
      });
    }
  };

  if (isTranslating) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Breadcrumb data={breadcrumbData.data} />
      <form className="w-full p-6 bg-white" onSubmit={handleSubmit}>
        <Box className="w-full" sx={{ mb: 3 }}>
          <h3 className="mb-6">{translatedLabels.changePassword}</h3>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={7}>
              <FormControl fullWidth>
                <Input
                  type="password"
                  label={translatedLabels.currentPassword}
                  name="currentPassword"
                  value={fieldValues.currentPassword}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={7}>
              <FormControl fullWidth>
                <Input
                  type="password"
                  label={translatedLabels.newPassword}
                  name="newPassword"
                  value={fieldValues.newPassword}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={7}>
              <FormControl fullWidth>
                <Input
                  type="password"
                  label={translatedLabels.confirmPassword} // Add confirmPassword input
                  name="confirmPassword"
                  value={fieldValues.confirmPassword}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>

            {/* Show password mismatch error if any */}
            {passwordMismatchError && (
              <Grid item xs={12}>
                <Typography color="error">{passwordMismatchError}</Typography>
              </Grid>
            )}
          </Grid>
        </Box>

        <Grid container xs={12} sm={1}>
          <div
            className={isMobile ? "w-full fixed bottom-0 left-0 p-6" : "w-full fixed bottom-0 left-0 p-6"}
            style={{
              bottom: buttonBottom,
              transition: "bottom 0.2s ease",
              zIndex: 9999,
            }}
          >
            <Box className="flex justify-center" style={{ paddingLeft: isMobile ? 0 : undefined }}>
              <Button
                className={isMobile ? "w-full" : "w-64"}
                type="submit"
                buttonText={translatedLabels.save}
                fullWidth={isMobile}
                isLoading={isLoading}
                sx={{
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: "2.5rem",
                  fontWeight: 700,
                  color: "#fff",
                }}
              />
            </Box>
          </div>
        </Grid>
      </form>
    </>
  );
};

export default ChangePassword;
